(function ($, win) {
    'use strict';

    win.dk = win.dk || {};

    const defaultSoloMode = true,
        expandLabels = {
            en: { expand: 'Expand All', collapse: 'Collapse All' },
            es: { expand: 'Expandir todo', collapse: 'Contraer todo' },
            fr: { expand: 'Tout d�velopper', collapse: 'Toute r�duire' }
        },
        pageLang = Object.keys(expandLabels)
            .map(l => $('.page-lang-' + l).length ? l : '').filter(l => l)[0] || 'en',
        isGlobalNoAnchors = $('html').is('[data-no-accordion-anchors]');

    function getExpandLabel(isExpanded) {
        return function () {
            // `this` in this context is the expand link element
            const $parent = $(this).closest('.dk-accdn'),
                localLang = $parent.is('[data-spanish]') ? 'es' : $parent.data('lang'),
                hasLang = Object.keys(expandLabels).includes(localLang),
                lang = hasLang ? localLang : pageLang;
            return expandLabels[lang][isExpanded ? 'collapse' : 'expand'];
        };
    }

    function moveDataAttributes($src, $tgt) {
        const moved = [];
        $src.first().each(function () {
            $.each(this.attributes, function () {
                if (this.name.match(/^data-/i)) {
                    $tgt.attr(this.name, this.value);
                    moved.push(this.name);
                }
            });
        });
        $src.removeAttr(moved.join(' '));
    }

    // Upgrade basic "convenience" markup (headings and content inside div.accordion)
    function $basicToFull($accordion) {
        if (!$accordion.length) return $accordion;
        const $accrd = $accordion.clone().removeAttr('data-no-expand-all'),
            $wrapper = $('<div class="dk-accdn"/>').append(
                $accordion.is('[data-no-expand-all]')
                    ? null
                    : $('<div class="acc-ctrl"><button type="button"/></div>'),
                $accrd
            ),
            $children = $accrd.children();

        if (!$children.length) {
            $accrd.removeClass();
            if ($accrd.text().trim()) {
                $accrd.wrapInner('<p/>');
            } else {
                // Empty accordion; hide it
                $accrd.addClass('empty hide');
            }
            return $accrd.addClass('accordion-invalid');
        }

        let $heading = $children.first();
        let h = $heading.prop('localName') || '';
        if (!h.match(/h[2-6]/i)) {
            return $accrd.removeClass().addClass('accordion-invalid');
        }
        const hSel = h + ':not(.literal,[data-literal])';
        do {
            $heading.addClass('acc-title').wrapInner('<button type="button"/>');
            if ($heading.is(':target')) $heading.addClass('open');
            let $cnt = $heading.nextUntil(hSel), div = '<div class="acc-content"/>';
            if ($cnt.length) $cnt.wrapAll(div);
            else $heading.after(div);
            $heading = $heading.nextAll(hSel).first();
        } while ($heading.length);

        $accrd.removeClass('accordion').addClass('acc-main');
        let lang = ($accordion.is('[data-spanish]') ? 'es' : $accordion.data('lang')) || 'en';
        $accrd.removeAttr('data-spanish');
        if (lang && lang !== 'en') $wrapper.attr('data-lang', lang);

        moveDataAttributes($accrd, $wrapper);

        return $wrapper;
    }

    function bindAccordion($accordion) {
        if (!$accordion.is('.dk-accdn') || $accordion.is('[data-ready]')) return;
        $accordion.attr('data-ready', 'true');

        function isSoloMode() {
            const solo = $accordion.data('solo');
            if (typeof solo === 'boolean') return solo;
            if (typeof solo === 'string') return solo !== 'false';
            if ($accordion.is('[data-multi]')) return false;
            return defaultSoloMode;
        }

        const allBtnCtrls = [],
            $titles = $('.acc-title', $accordion),
            titleCount = $titles.length,
            isNoAnchors = isGlobalNoAnchors || $accordion.is('[data-no-anchors]');

        // No expand all button if there are less than two sections
        let $allBtn = $('.acc-ctrl button', $accordion);
        if (titleCount < 2) {
            $allBtn.remove();
            $allBtn = null;
        } else if (!$allBtn.length) {
            $allBtn = null;
        }

        function updateHistory(hsh) {
            if (isNoAnchors) return;
            const u = new win.URL(win.location);
            u.hash = hsh || '';
            if (u.href === location.href) return;
            if ('replaceState' in win.history) {
                const title = $('title').text() || 'NIDDK';
                win.history.replaceState('', title, u.href);
            }
        }

        function getOpenCount() { return $titles.filter('.open').length; }

        // Sets the state of the expand all button
        function updateAllButtonState() {
            if (!$allBtn) return;
            const openCount = getOpenCount();
            $allBtn.attr({
                'aria-pressed': (titleCount === openCount ? true : (!openCount ? false : 'mixed')),
                'aria-expanded': titleCount === openCount
            }).text(getExpandLabel(titleCount === openCount));
        }

        // Sets open, closed, or toggle on all the targets
        // op = [1: open, 0: toggle, -1: close]
        function setExpanded($targets, op) {
            $targets = $targets.jquery ? $targets : $($targets);
            const isSolo = isSoloMode(),
                openBeforeCount = isSolo ? getOpenCount() : 0;
            // Updates the overall accordion after animations are complete
            const afterUpdate = win.dk.debounce(function (justOpened) {
                    updateAllButtonState();
                    if (getOpenCount() === 1) {
                        const $open = $targets.first('.open');
                        updateHistory($open.prop('id'));
                        if (isSolo && (openBeforeCount < 2 || justOpened)) {
                            // Only one section open at a time in solo mode; scroll to it
                            $('html,body').animate({
                                scrollTop: $open.offset().top - 85
                            }, 500);
                        }
                    } else {
                        updateHistory();
                    }
                }, 100);
            // Sets the attributes and visibility of a section
            const setAttributes = function ($title, open, skipAfterUpdate) {
                const $btn = $('button', $title),
                    $content = $title.next('.acc-content');
                $btn.attr('aria-expanded', open);
                if (open) {
                    $content.hide().prop('hidden', false);
                    $title.addClass('open');
                }
                $content[open ? 'slideDown' : 'slideUp'](function () {
                    if (!open) {
                        $content.prop('hidden', true);
                        $title.removeClass('open');
                    }
                    if (!skipAfterUpdate) afterUpdate(open); // New state is `open`; pass as justOpened
                });
            };
            if (isSolo && $targets.length === 1) {
                // In solo mode, close the other sections if a single section is opening
                const $target = $targets.first(),
                    isOpen = $target.is('.open'),
                    // isClose: !~op, isToggle: !op, isOpen: !(!~op || !op)
                    shouldOpen = !(!~op || (!op && isOpen));
                if (!isOpen && shouldOpen) {
                    // Only close others if the target is opening
                    $titles.not($targets).each(function () {
                        setAttributes($(this), false, true);
                    });
                }
            }
            // Toggle each affected section
            $targets.each(function () {
                const $title = $(this),
                    isOpen = $title.is('.open'),
                    // isClose: !~op, isToggle: !op, isOpen: !(!~op || !op)
                    shouldOpen = !(!~op || (!op && isOpen));
                if (shouldOpen === isOpen) return;
                setAttributes($title, shouldOpen);
            });
        }

        $titles.each(function () {
            const $title = $(this),
                titleId = win.dk.generateFriendlyId($title, true);
            if ($title.is(':target') || location.hash === '#' + titleId) $title.addClass('open');
            const isOpen = $title.is('.open'),
                hasButton = !$title.is('.nobtn'),
                $btn = hasButton ? $('button', $title) : $('<button type="button"/>'),
                btnId = win.dk.ensureId($btn, 'accbtn-'),
                $content = $title.next('.acc-content'),
                cntId = win.dk.ensureId($content, 'acccnt-');
            if (!hasButton) $title.wrapInner($btn).removeClass('nobtn');
            $content.attr({
                role: 'region',
                'aria-labelledby': btnId
            }).prop('hidden', !isOpen);
            $btn.attr({
                type: 'button',
                'aria-controls': cntId,
                'aria-expanded': isOpen
            }).off().on('click', function (evt) {
                evt.preventDefault();
                setExpanded(this.parentElement);
            });
            allBtnCtrls.push(cntId);
        });

        if (!$allBtn) return;

        updateAllButtonState();
        $allBtn.attr({
                type: 'button',
                'aria-controls': allBtnCtrls.join(' '),
                'aria-label': getExpandLabel(false)
            })
            .off().on('click', function (evt) {
                evt.preventDefault();
                const isExpand = getOpenCount() !== titleCount;
                setExpanded($titles, isExpand ? 1 : -1);
            });
    }

    function upgradeMarkup($accordion) {
        if ($accordion instanceof Element) $accordion = $($accordion);
        if (!$accordion.jquery || $accordion.is('.off,[data-ready]')) return;
        let $upgraded = $accordion;
        if ($accordion.is('.accordion'))
            $upgraded = $basicToFull($accordion);
        if (!$upgraded || !$upgraded.is('.dk-accdn,.accordion-invalid')) return;
        if (!$upgraded.is('.accordion-invalid'))
            bindAccordion($upgraded);
        $accordion.replaceWith($upgraded);
    }

    function bindAccordions() {
        $('.accordion,.dk-accdn').each(function () {
            upgradeMarkup(this);
        });
    }

    win.dk.bindAccordions = bindAccordions;

    $(function () {
        if (!win.dk.isEditing) {
            // For accordions in body content, generate the accordion structure when not in edit mode
            bindAccordions();

            window.addEventListener('hashchange', function (e) {
                if (e.oldURL === e.newURL) return;
                const u = new win.URL(e.newURL);
                if (!u.hash) return;
                const $target = $(u.hash + '.acc-title');
                if ($target.closest('.dk-accdn').length) {
                    if (!$target.is('.open'))
                        $('button', $target).trigger('click');
                }
            });
        }
    });
})(window.jQuery, window);
