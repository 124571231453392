(function (win, $) {
    'use strict';

    var dk = win.dk,
        stickyDefaults = {
            ft: 64, // fixedTop
            cmt: 50, // containerMinTop
            cbmo: 76, // containerBottomMinOffset
            cbp: 14 // containerBottomPad
        },
        fixedTop = stickyDefaults.ft, // The top position when the panel is sticky (fixed)
        containerMinTop = stickyDefaults.cmt, // The minimum top offset for .fs-body before the summary becomes sticky
        containerBottomMinOffset = stickyDefaults.cbmo, // The minimum offset for the bottom of .fs-body (less the height of the panel box) before the panel reattaches
        containerBottomPad = stickyDefaults.cbp; // The distance from the bottom of .fs-body at which the panel will reattach

    function updateStickyVals(isTablet) {
        function val(v) {
            return isTablet ? dk.toTabletPx(v) : v;
        }
        fixedTop = val(stickyDefaults.ft);
        containerMinTop = val(stickyDefaults.cmt);
        containerBottomMinOffset = val(stickyDefaults.cbmo);
        containerBottomPad = val(stickyDefaults.cbp);
    }

    $(function () {
        var $ctr = $('.fs-body:first'), $win, $facets, $parent;

        // Only execute if this is a faceted search page
        if (!$ctr.length) return;

        $win = $(win);
        $facets = $('.fs-facet-slide:first', $ctr); // need to update when results change
        $parent = $facets.parent();

        function resetFacetLocation() {
            var summaryBox = $facets[0].getBoundingClientRect(),
                ctrBox = $ctr[0].getBoundingClientRect();
            if (ctrBox.top <= containerMinTop && (ctrBox.bottom >= containerBottomMinOffset + summaryBox.height)) {
                $facets.addClass('stuck').css({ 'position': 'fixed', 'top': fixedTop, 'left': summaryBox.left, 'right': 'auto', 'cursor': '' });
            } else if (ctrBox.bottom < containerBottomMinOffset + summaryBox.height) {
                $facets.removeClass('stuck').css({ 'position': 'absolute', 'top': ctrBox.height - summaryBox.height - containerBottomPad, 'left': '', 'right': '', 'cursor': 'pointer' });
            } else {
                $facets.removeClass('stuck').css({ 'position': '', 'top': '', 'left': '', 'right': '', 'cursor': '' });
            }
        }

        $win.on('dk:widthChanged', function (_, data) {
            var evt = 'scroll.resultSummary';
            if (data.scaleChanged) updateStickyVals(data.isTablet);
            $facets.css({ 'position': '', 'top': '', 'left': '', 'right': '', 'width': $parent.width() });
            if (data.layoutChanged) {
                $ctr.css('position', data.isMobile ? '' : 'relative');
                $win.off(evt);
                if (!data.isMobile) $win.on(evt, () => resetFacetLocation());
            }
            if (!data.isMobile) resetFacetLocation();
        });
    });
})(window, window.jQuery);
