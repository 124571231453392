(function (win, $) {
    'use strict';

    function initializeForm() {
        var $form = $(this),
            isRefine, $txtQuery, $withinInputs;

        if (!$form.is('form') || $form.data('init')) return;

        isRefine = $form.is('.sr-refine');
        $txtQuery = $('input:text:first', $form);
        $withinInputs = $(':radio[name="w"]', $form);

        $withinInputs.first().prop('checked', true);

        $form.on('submit', function (e) {
            var val = ($txtQuery.val() || '').replace(/^\s+|\s+$/g, '').replace(/\s{2,}/g, ' '), $prevQuery, isWithin;
            if (val.length > 0) {
                $txtQuery.val(val);
                if (isRefine) {
                    $prevQuery = $('input[name="wq"]', $form);
                    isWithin = $withinInputs.filter('[value="1"]:first').prop('checked');
                    $withinInputs.removeAttr('name');
                    if (!isWithin) {
                        $prevQuery.removeAttr('name');
                    }
                }
                return true;
            } else {
                e.preventDefault();
                window.alert('Please enter keywords before searching.');
                $txtQuery.trigger('focus');
                if ($txtQuery[0]) {
                    $txtQuery[0].select();
                }
                return false;
            }
        }).data('init', 1);
    }

    // Site navigation and mobile toolbar setup
    function initializeSiteHeader() {
        var $header = $('.site-header:first'),
            $search = $('.dk-site-search', $header),
            searchId = win.dk.ensureId($search),
            $toolbar = $('.dk-site-mobile-toolbar', $header),
            $mobileSearchButton = $('>.mobile-site-search', $toolbar),
            slideDuration = 250;

        function unbind() {
            $search.removeAttr('style');
            $mobileSearchButton.removeAttr('aria-expanded');
        }

        function bindMobile() {
            function bindToolbarButton($btn, ctId, $elt, action) {
                $btn
                    .attr({
                        'aria-controls': ctId,
                        'aria-expanded': false
                    })
                    .off()
                    .on('click', function (e) {
                        var $me = $(this),
                            isExpanded = $me.attr('aria-expanded') === 'true'
                            ;
                        function act() {
                            if (typeof action === 'function') { action(!isExpanded); }
                        }

                        e.preventDefault();
                        $elt[isExpanded ? 'slideUp' : 'slideDown'](slideDuration, act);
                        $me.attr('aria-expanded', !isExpanded);
                        return false;
                    });
            }

            bindToolbarButton($mobileSearchButton, searchId, $search, function (exp) {
                if (exp) { $('input:text', $search).trigger('focus'); }
            });
        }

        if (!$search.data('init')) {
            $(win).on('dk:siteLayoutChanged', function (_, isMobile) {
                unbind();
                if (isMobile) { bindMobile(); }
            });

            $search.data('init', 1);
        }
    }

    $(function () {
        $('#site-search,.sr-refine').each(initializeForm);
        initializeSiteHeader();
    });
})(window, window.jQuery);
